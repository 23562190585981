/* css custom properties */
:root {
  --white: #ffffff;
  --black: #000000;
  --dark: #0f0d26;

  --nav-header-bg: #ffffff;
  --nav-header-sticky-bg: #ffffff;
  --nav-mobile-header-bg: rgba(255, 255, 255, 0.9);
  --nav-mobile-header-sticky-bg: rgba(255, 255, 255, 0.9);
  --nav-mobile-header-active-bg: #ffffff;

  --pastel-blue-100: #86b8df;
  --pastel-blue-200: #c3dcef;
  --pastel-blue-300: #e8f0f6;
  --teal-100: #202c2c;
  --teal-200: #d0dddd;
  --teal-300: #f1faf4;
  --green-100: #0fbd69;
  --green-200: #33d989;
  --yellow-100: #ffe270;

  /* new color tokens */
  --blue-10: hsla(246, 100%, 95%, 1);
  --blue-20: hsla(246, 100%, 90%, 1);
  --blue-30: hsla(246, 100%, 80%, 1);
  --blue-40: hsla(246, 100%, 70%, 1);
  --blue-50: hsla(246, 100%, 45%, 1);
  --blue-60: hsla(246, 100%, 35%, 1);
  --blue-70: hsla(246, 100%, 25%, 1);
  --blue-80: hsla(246, 100%, 20%, 1);
  --blue-90: hsla(246, 100%, 10%, 1);
  --yellow-10: hsla(45, 50%, 97%, 1);
  --yellow-20: hsla(45, 60%, 90%, 1);
  --yellow-30: hsla(45, 100%, 80%, 1);
  --yellow-40: hsla(45, 100%, 70%, 1);
  --yellow-50: hsla(45, 100%, 60%, 1);
  --yellow-60: hsla(45, 100%, 50%, 1);
  --yellow-70: hsla(45, 100%, 40%, 1);
  --yellow-80: hsla(45, 100%, 30%, 1);
  --yellow-90: hsla(45, 100%, 10%, 1);
  --purple-10: hsla(265, 77%, 97%, 1);
  --purple-20: hsla(265, 77%, 85%, 1);
  --purple-30: hsla(265, 77%, 75%, 1);
  --purple-40: hsla(265, 77%, 60%, 1);
  --purple-50: hsla(265, 77%, 50%, 1);
  --purple-60: hsla(265, 77%, 40%, 1);
  --purple-70: hsla(265, 60%, 30%, 1);
  --purple-80: hsla(265, 60%, 25%, 1);
  --purple-90: hsla(265, 60%, 10%, 1);
  --orange-10: hsla(14, 100%, 96%, 1);
  --orange-20: hsla(14, 100%, 85%, 1);
  --orange-30: hsla(14, 100%, 80%, 1);
  --orange-40: hsla(14, 100%, 75%, 1);
  --orange-50: hsla(14, 100%, 60%, 1);
  --orange-60: hsla(14, 100%, 50%, 1);
  --orange-70: hsla(14, 100%, 45%, 1);
  --orange-80: hsla(14, 100%, 35%, 1);
  --orange-90: hsla(14, 100%, 10%, 1);
  --cyan-10: hsla(188, 100%, 95%, 1);
  --cyan-20: hsla(188, 100%, 85%, 1);
  --cyan-30: hsla(188, 100%, 75%, 1);
  --cyan-40: hsla(188, 100%, 65%, 1);
  --cyan-50: hsla(188, 100%, 50%, 1);
  --cyan-60: hsla(188, 100%, 45%, 1);
  --cyan-70: hsla(188, 100%, 40%, 1);
  --cyan-80: hsla(188, 100%, 30%, 1);
  --cyan-90: hsla(188, 100%, 10%, 1);
  --magenta-10: hsla(334, 100%, 97%, 1);
  --magenta-20: hsla(334, 100%, 85%, 1);
  --magenta-30: hsla(334, 100%, 75%, 1);
  --magenta-40: hsla(334, 100%, 65%, 1);
  --magenta-50: hsla(334, 100%, 50%, 1);
  --magenta-60: hsla(334, 100%, 40%, 1);
  --magenta-70: hsla(334, 100%, 35%, 1);
  --magenta-80: hsla(334, 100%, 30%, 1);
  --magenta-90: hsla(334, 100%, 12%, 1);
  --green-10: hsla(148, 75%, 95%, 1);
  --green-20: hsla(148, 75%, 85%, 1);
  --green-30: hsla(148, 75%, 75%, 1);
  --green-40: hsla(148, 75%, 60%, 1);
  --green-50: hsla(148, 75%, 50%, 1);
  --green-60: hsla(148, 75%, 45%, 1);
  --green-70: hsla(148, 75%, 40%, 1);
  --green-80: hsla(148, 75%, 30%, 1);
  --green-90: hsla(148, 75%, 10%, 1);
  --gray-10: hsla(210, 17%, 98%, 1);
  --gray-20: hsla(210, 16%, 93%, 1);
  --gray-30: hsla(210, 14%, 89%, 1);
  --gray-40: hsla(210, 14%, 80%, 1);
  --gray-50: hsla(210, 10%, 65%, 1);
  --gray-60: hsla(210, 10%, 45%, 1);
  --gray-70: hsla(210, 9%, 30%, 1);
  --gray-80: hsla(210, 10%, 20%, 1);
  --gray-90: hsla(210, 11%, 10%, 1);
  --gray-100: hsla(247, 45%, 8%, 1);
  --gray-700: #464d53;
  --gray-600: #67737e;

  /* new page color tokens */
  --blue-web-27: hsla(246, 27%, 23%, 1);
  --blue-web-30: hsla(246, 100%, 85%, 1);
  --blue-web-40: hsla(246, 100%, 70%, 1);
  --blue-web-50: hsla(246, 100%, 56%, 1);
  --blue-web-60: hsla(246, 100%, 46%, 1);
  --blue-web-70: hsla(246, 100%, 38%, 1);
  --blue-web-90: hsla(246, 80%, 8%, 1);
  /* width tokens */
  --max-width-desktop: 1240px;
  --md: 768px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* utility styles from tailwind classes */
@layer components {
  .max-width-container {
    @apply max-desktop-width;
    @apply mx-auto;
    @apply px-5 sm:px-10 lg:px-6;
  }

  .focus-ring {
    @apply focus:outline-none focus:ring-2;
  }

  .focus-ring-lg {
    @apply focus:outline-none focus:ring-4;
  }

  .focus-visible-ring {
    @apply focus:outline-none focus-visible:ring-2;
  }

  .focus-visible-ring-lg {
    @apply focus:outline-none focus-visible:ring-4;
  }

  /* button styles */
  .btn-primary {
    @apply bg-blue-50 text-white font-medium;
    @apply rounded-full;
    @apply py-4 px-8;
    @apply inline-flex items-center justify-center;
  }

  .btn-secondary {
    @apply bg-blue-10 text-blue-50 font-medium;
    @apply rounded-full;
    @apply py-4 px-8;
    @apply inline-flex items-center justify-center;
  }

  .btn-size-default {
    @apply py-2;
  }

  /* new button styles */
  .new-btn-primary {
    @apply bg-blue-50 text-white text-paragraph-1;
    @apply rounded-md;
    @apply max-h-14 py-4 px-6;
    @apply inline-flex items-center justify-center;
  }

  .new-btn-secondary {
    @apply text-white text-paragraph-1;
    @apply rounded-md;
    @apply max-h-14 py-4 px-6;
    @apply inline-flex items-center justify-center;
    @apply border border-white;
  }

  .new-btn-tertiary {
    @apply bg-yellow-60 text-blue-web-90 text-paragraph-1;
    @apply rounded-md;
    @apply max-h-14 py-4 px-6;
    @apply inline-flex items-center justify-center;
  }

  .new-btn-neutral {
    @apply text-white text-paragraph-1;
    @apply rounded-md;
    @apply max-h-14 py-4 px-6;
    @apply inline-flex items-center justify-center;
  }

  .new-btn-blue-outline {
    @apply text-blue-web-90 text-paragraph-1;
    @apply rounded-md;
    @apply max-h-14 py-4 px-6;
    @apply inline-flex items-center justify-center;
    @apply border border-blue-web-90;
  }
  .new-pill-btn-tertiary {
    @apply bg-yellow-60 text-gray-90 text-paragraph-1;
    @apply rounded-full;
    @apply max-h-14 py-3 px-6;
    @apply inline-flex items-center justify-center;
  }
}

body {
  color: var(--gray-90);
  font-feature-settings: 'ss01' on, 'ss03' on;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  accent-color: var(--blue-40);
}

/* width utilities */
.max-desktop-width {
  max-width: var(--max-width-desktop);
}

.max-header-width {
  max-width: 90rem;
}

/* font-size utilities */
.h1 {
  font-size: 48px;
  line-height: 1.2;
}

.h1--alt {
  font-size: 40px;
  line-height: 1.2;
}

.h2 {
  font-size: 32px;
  line-height: 1.2;
}

.h3 {
  font-size: 28px;
  line-height: 1.3;
}

.h4 {
  font-size: 24px;
  line-height: 1.3;
}

.h5 {
  font-size: 20px;
  line-height: 1.3;
}

.h5-alt {
  font-size: 22px;
  line-height: 1.3;
}
.h2-mobile {
  font-size: 32px;
  line-height: 1.2;
}

.h3-mobile {
  font-size: 28px;
  line-height: 1.3;
}

.h4-mobile {
  font-size: 24px;
  line-height: 1.3;
}

@media (min-width: 640px) {
  .h1 {
    font-size: 64px;
    line-height: 1.2;
    letter-spacing: -0.015em;
  }

  .h1--alt {
    font-size: 54px;
    line-height: 1.2;
  }

  .h2 {
    font-size: 44px;
    line-height: 1.2;
  }

  .h3 {
    font-size: 36px;
    line-height: 1.3;
  }

  .h4 {
    font-size: 28px;
    line-height: 1.3;
  }
}

/* text utilities */
.text-underline-t-2 {
  text-decoration: underline;
  text-decoration-color: var(--yellow-50);
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
}

.text-underline-t-4 {
  text-decoration: underline;
  text-decoration-color: var(--yellow-50);
  text-decoration-thickness: 4px;
  text-underline-offset: 3px;
}

/* global styles */
#js-blog-list {
  scroll-margin-top: 80px;
}

.tap-transparent {
  -webkit-tap-highlight-color: transparent;
}
